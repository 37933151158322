body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.authorBlock {
  display: inline-block;
}

.gridContainer {
  width: 880px;
  height: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 110px;
}

.interactive {
  position: relative;
  width: 880px;
  height: 450px;
  margin-top: -450px;
}

.grid {
  background: black;
  font-size: 0;
}

.grid div {
  width: 80px;
  height: 50px;
  border-bottom: 0.5px solid black;
  border-left: 0.5px solid black;
  background: white;
  display: inline-block;
}

.graphData {
  width: 12px;
  height: 12px;
  border-radius: 30px;
  background: darkorange;
  position: absolute;
}

.nbitsTitle {
  transform: rotate(-90deg);
  position: absolute;
  margin-top: 200px;
  margin-left: -120px;
}
